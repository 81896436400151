<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
    <b-overlay
      :show="LOADER"
      no-wrap
      variant="dark"
      :opacity="0.3"
    />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { createNamespacedHelpers } from 'vuex'

const appModule = createNamespacedHelpers('app')
export default {
  computed: {
    ...appModule.mapState(['LOADER']),
  },
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
